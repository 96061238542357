.footer_container {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 140px;
  position: relative;
  margin-top: 48px;
  overflow: hidden;

  :global {
    div {
      position: relative;
      z-index: 2;
    }
  }
}

.footer_title {
  height: 94px;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 94px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 42px;
  font-family: 'Poppins' !important;
}

.footer_download {
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;

  // :global {
  //   a {
  //     // width: 205px;
  //     margin-right: 20px;
  //   }
  // }

  .qrcode {
    border-radius: 8px;
    padding: 8px;
    background-color: #fff;
  }

  &_appStore {
    width: 486px;

    a {
      display: inline-block;
      margin-right: 20px;
    }

    a:nth-child(4) {
      margin-top: 20px;
    }
  }
}

.site_map_title {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 18px;

  @media screen and (max-width: 540px) {
    margin-left: 0.2rem;
    font-family: 'Product Sans';
    font-weight: 700;
    font-size: 0.14rem;
    line-height: 0.2rem;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
}

.footer_site_map {
  width: 1118px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 160px;
  // text-transform :capitalize
}

.site_map_col {
  :global {
    a {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #ffffff;
    }
  }
}

.site_copy_right {
  font-family: 'Product Sans';
  padding-top: 45px;
  padding-bottom: 90px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.0075em;
  color: #ffffff;
  width: 550px;
  margin: 0 auto;

  p {
    margin-bottom: 0;
    margin-top: 10px;

    span {
      padding: 0 10px;
    }
  }

  @media screen and (max-width: 540px) {
    width: 3.2rem;
    padding-top: 0.45rem;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* or 125% */

    letter-spacing: 0.0075em;

    /* Neutral/White */

    color: #ffffff;
  }
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  border-radius: 0px 200px 0px 0px;
}

.site_map_follow {
  display: flex;
  justify-content: flex-start;
  width: 125px;
  flex-wrap: wrap;

  @media screen and (max-width: 540px) {
    width: 3.2rem;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  :global {
    a {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    img {
      width: 32px;
      height: 32px;

      @media screen and (max-width: 540px) {
        width: 0.45rem;
      }
    }
  }
}

.mobile_footer {
  position: relative;
  height: 1100px;
}

.mobile_bg {
  @extend .bg;
  border-radius: 0px 50px 0px 0px;
  height: 100%;
}

.mobile_footer_title {
  width: 2.8rem;
  font-weight: 700;
  font-size: 0.32rem;
  line-height: 0.4rem;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 0.35rem;
  padding-top: 0.83rem;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Poppins' !important;
}

.site_map_link {
  &:hover {
    color: #ffc635;
  }

  &:active {
    color: #ffc635;
  }
}

.mobile_footer_download {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.7rem;

  // :global {
  //   img {
  //     width: 1.3rem;
  //     margin-right: 0.16rem;
  //   }
  // }

  &_appStore {
    width: 3.2rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;

    a {
      display: inline-block;
      margin: 0 5px;
      margin-top: 20px;
    }
  }

  @media (max-width: 400px) {
    &_appStore {
      padding: 0;
    }
  }
}

.mobile_footer_container {
  position: relative;
  z-index: 2;
}

.mobile_list_title {
  color: #ffffff !important;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 18px;
}

.mobile_list {
  color: #ffffff;
}

.mobile_list_container {
  width: 3.2rem;
  margin: 0 auto;
}

.mobile_footer_link {
  &:active {
    color: #ffc635;

    :global {
      span {
        color: #ffc635 !important;
      }
    }
  }

  :global {
    span {
      color: #ffffff !important;
    }
  }
}

.mobile_follow_us {
  margin-top: 32px;
}

.mobile_bg_wrapper {
  overflow: hidden;
  border-radius: 0px 50px 0px 0px;
  @extend .bg;

  .mobile_bg {
    border-radius: 0px 50px 0px 0px;
    height: 100%;
  }
}